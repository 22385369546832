import classes from '../classes/Contacs.module.css'
import vodafone from '../img/vodafone_icon.png'
import life from '../img/lifecell_logo.webp'
import kyivstar from '../img/kyivstar_icon.webp'
import google from "../img/google_play.webp";
import {useTranslation} from "react-i18next";
import appStore from "../img/app_store.webp";

const Contacts = () => {
    const {t} = useTranslation()
    return (
        <section className={classes.bgImgVas}>
            <div className={classes.positionCenter}>
                <div className="container">
                    <div className={classes.text}>
                        <h1>{t('contact_our')}</h1>
                    </div>
                    <div className="row">
                        <div className='col-md-4 col-12 '>
                            <div className='text-center border border-2 border-white rounded p-2 my-md-4 my-3'>
                                <div className={classes.blocks}>
                                    <h3 className=''>{t('contact_mobile')}</h3>
                                    <div className={classes.mobiles}>
                                        <a className='link-light text-decoration-none' href='tel:0932237070'><p
                                            className='flex'>
                                            <img className='d-inline-block mx-1'
                                                 width='30px'
                                                 height='30px'
                                                 src={life}/>
                                            093 223 70 70
                                        </p></a>
                                        <a className='link-light text-decoration-none' href='tel:0982237070'><p
                                            className=' flex'>
                                            <img className='d-inline-block mx-1'
                                                 width='30px'
                                                 height='30px'
                                                 src={kyivstar}/>
                                            098 223 70 70
                                        </p>
                                        </a>
                                        <a className='link-light text-decoration-none' href='tel:0952237070'><p
                                            className=' flex'>
                                            <img className='d-inline-block mx-1'
                                                 width='30px'
                                                 height='30px'
                                                 src={vodafone}/>
                                            095 223 70 70
                                        </p>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-12">
                            <div className='text-center border border-2 border-white rounded  p-2 my-md-4 my-3 '>
                                <div className={classes.blocks}>
                                    <h3 className=''>{t('contact_mail')}</h3>
                                    <div className={classes.mobiles}>
                                        <p className='flex fbo'>
                                            <a href='mailto: taxisimk@taxi-simka.com' style={{color: 'white'}}><i
                                                className="bi bi-envelope-fill mx-1"></i>
                                                taxi.simka@gmail.com</a>
                                        </p>
                                    </div>
                                    <div className={classes.imgGoogle}>
                                        <p style={{marginBottom: 0}}>{t('contact_app')}:</p>
                                        <a href='https://play.google.com/store/apps/details?id=ua.taxisimka' rel='nofollow'>
                                            <img
                                                className='img-fluid'
                                                src={google}
                                                alt="google play link"/>
                                        </a>
                                        <a href='https://apps.apple.com/us/app/%D1%82%D0%B0%D0%BA%D1%81%D1%96-%D1%81%D1%96%D0%BC%D0%BA%D0%B0-%D0%B2%D0%B0%D1%81%D0%B8%D0%BB%D1%8C%D0%BA%D1%96%D0%B2/id6446292238' rel='nofollow'>
                                            <img
                                              src={appStore}
                                              alt="app store link"/>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-12">
                            <div className='text-center border border-2 border-white rounded  p-2 my-md-4 my-3 '>
                                <div className={classes.blocks}>
                                    <h3 className=''>{t('contact_quality')}</h3>
                                    <div className={classes.mobiles2}>
                                        <a className='link-light text-decoration-none fs-4' href='tel: 0930352581'>
                                            <p className='flex fbo'>
                                            <i className="bi bi-phone mx-1"></i>
                                            093 035 25 81
                                        </p></a>
                                    </div>
                                    <br/>
                                    <h3 className=''>{t('contact_job')}</h3>
                                    <div className={classes.mobiles2}>
                                        <a className='link-light text-decoration-none fs-4' href='tel: 0936552734'>
                                            <p className='flex'>
                                                <i className="bi bi-phone mx-1"></i>
                                                093 655 27 34
                                            </p></a>
                                    </div>
                                    <br/>
                                    <div className={classes.mobiles2}>
                                        <a className='link-light text-decoration-none fs-4' href='tel: 0637208050'>
                                            <p className='flex'>
                                                <i className="bi bi-phone mx-1"></i>
                                                063 720 80 50
                                            </p></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="text-center pb-4">
                        <h4>{t('contact_time')}</h4>
                    </div>
                    <div className="text-center pb-4">
                        <h4>Фастів, Соборна 21, Київська область, Україна, 08500</h4>
                        <h4>Васильків, Грушевського 17, Київська область, Україна, 08601</h4>
                    </div>
                </div>
            </div>
        </section>

    );
};

export default Contacts;
