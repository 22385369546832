import {BrowserRouter} from "react-router-dom";
import classes from "./classes/App.css";
import {lazy, Suspense, useState} from "react";
import AppRouter from "./components/AppRouter";
import i18n from "./i18n";

const Footer = lazy(() => import("./components/Footer"));
const ButtonPhone = lazy(() => import("./components/buttonPhone"));
const BannerComponent = lazy(() => import("./components/bannerComponent"));
const MobileBannerComponent = lazy(() => import("./components/mobileBannerComponent"));
const ButtonVacancies = lazy(() => import("./components/buttonVacancies"));
const NavBar = lazy(() => import("./components/NavBar"))
i18n.init()

function App() {


    return (
        <BrowserRouter>
            <Suspense fallback={<div style={{width: '100vw', height: '110px', backgroundColor: '#212529'}}></div>}>
                <NavBar/>
            </Suspense>
            <AppRouter/>
            <Suspense>
                <BannerComponent/>
                <MobileBannerComponent/>
                <ButtonVacancies/>
                <ButtonPhone/>
            </Suspense>
            <Suspense fallback={<p style={{
                width: '100vw',
                height: '200px',
                marginBottom: '-20px',
                backgroundColor: '#212529'
            }}>Loading...</p>}>
                <Footer/>
            </Suspense>
        </BrowserRouter>
    );
}

export default App;
