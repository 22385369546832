import React from 'react';
import classes from "../classes/Drivers.module.css";
import google from "../img/google_play.webp";
import {Button, Nav} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";

const DriversOwnCar = () => {
    const {t} = useTranslation()
    const {lang} = useParams();

    return (
        <div className={classes.bg}>
            <div className="container text-center">
                <div className='text-center p-0 pt-5 mb-0'>
                    <p>{t('drivers_first_name')}
                    </p>
                </div>
                <div className={classes.list}>
                    <dt>{t('drivers_conditions')}</dt>
                    <dd><i className="bi bi-check"
                           style={{color: 'lime', fontSize: '25px', marginRight: '10px'}}></i> {t('drivers_owncar')}
                    </dd>
                    <dd><i className="bi bi-check"
                           style={{color: 'lime', fontSize: '25px', marginRight: '10px'}}></i> {t('drivers_grafik')}
                    </dd>
                    <dd><i className="bi bi-check"
                           style={{color: 'lime', fontSize: '25px', marginRight: '10px'}}></i> {t('drivers_dopposlug')}
                    </dd>
                    <dd><i className="bi bi-check"
                           style={{color: 'lime', fontSize: '25px', marginRight: '10px'}}></i> {t('drivers_queue')}
                    </dd>
                    <dd><i className="bi bi-check"
                           style={{color: 'lime', fontSize: '25px', marginRight: '10px'}}></i> {t('drivers_comfort')}
                    </dd>
                    <dd><i className="bi bi-check"
                           style={{color: 'lime', fontSize: '25px', marginRight: '10px'}}></i> {t('drivers_lowcomm')}
                    </dd>
                    <dd><i className="bi bi-check"
                           style={{color: 'lime', fontSize: '25px', marginRight: '10px'}}></i> {t('drivers_choise')}
                    </dd>
                </div>

                <div className={classes.list}>
                    <dt>{t('drivers_requirements')}</dt>
                    <dd><i className="bi bi-check"
                           style={{color: 'lime', fontSize: '25px', marginRight: '10px'}}></i> {t('drivers_age')}
                    </dd>
                    <dd><i className="bi bi-check"
                           style={{color: 'lime', fontSize: '25px', marginRight: '10px'}}></i> {t('drivers_auto')}
                    </dd>
                    <dd><i className="bi bi-check"
                           style={{color: 'lime', fontSize: '25px', marginRight: '10px'}}></i> {t('drivers_phone')}</dd>
                    <dd><i className="bi bi-check"
                           style={{color: 'lime', fontSize: '25px', marginRight: '10px'}}></i> {t('drivers_experience')}
                    </dd>
                    <dd><i className="bi bi-check"
                           style={{color: 'lime', fontSize: '25px', marginRight: '10px'}}></i> {t('drivers_city')} </dd>
                    <dd><i className="bi bi-check"
                           style={{color: 'lime', fontSize: '25px', marginRight: '10px'}}></i> {t('drivers_expmobile')}
                    </dd>
                    <dd><i className="bi bi-check"
                           style={{color: 'lime', fontSize: '25px', marginRight: '10px'}}></i> {t('drivers_badhabits')}
                    </dd>
                    <dd><i className="bi bi-check"
                           style={{color: 'lime', fontSize: '25px', marginRight: '10px'}}></i> {t('drivers_stressres')}
                    </dd>
                    <dd><i className="bi bi-check" style={{
                        color: 'lime',
                        fontSize: '25px',
                        marginRight: '10px'
                    }}></i> {t('drivers_communicability')} </dd>
                    <dd><i className="bi bi-check"
                           style={{color: 'lime', fontSize: '25px', marginRight: '10px'}}></i> {t('drivers_collective')}
                    </dd>
                </div>
                <div className={classes.imgGoogle}>
                    <p className='pt-4'>{t('drivers_mobilefordrivers')}</p>
                    <a href='https://play.google.com/store/apps/details?id=ua.com.mobilauto.driver'>
                        <img
                            src={google}
                            alt="google play link"/>
                    </a>
                </div>
                <div className='mt-4'>
                    <p>{t('drivers_ifagree')}</p>
                    <div className={classes.mobiles2}>
                        <a className='link-light text-decoration-none fs-4' href='tel: 0936552734'>
                            <p className='flex'>
                                <i className="bi bi-phone mx-1"></i>
                                093 655 27 34
                            </p></a>
                    </div>
                    <div className={classes.mobiles2}>
                        <a className='link-light text-decoration-none fs-4' href='tel: 0637208050'>
                            <p className='flex'>
                                <i className="bi bi-phone mx-1"></i>
                                063 720 80 50
                            </p></a>
                    </div>
                    <Nav.Link href={`/feedback${lang === 'ru' ? '/ru' : ''}`}>
                        <Button className="primaryNavButton button" variant="primary" size="lg">{t('drivers_form')}</Button>
                    </Nav.Link>
                </div>
            </div>
        </div>
    );
};

export default DriversOwnCar;
